<template>
    <div class="bg-white  pb-8 mt-8">
        <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4">
          <div class="title-text">Product Details</div>
          <!-- <div class="d-flex justify-end align-start">
            <v-btn
                x-small
                outlined
                v-if="productCombinations.length > 1"
                @click="chooseRentalCombination"
            ><v-icon x-small>mdi-cart-arrow-right</v-icon> Rental combination</v-btn
            >
          </div> -->
        </div>
  
  
      <v-row class="mt-2">
  
        <div class="d-flex justify-space-between pa-4 w-full">
          <v-col :md="productCategoryId == -1 ? 2 : 3">
            <label for="">Select Category</label>
            <v-autocomplete
                :items="categoriesList"
                v-model="productCategoryId"
                label=""
                required
                item-value="id"
                item-text="name"
                @change="categoryChange($event)"
                outlined
                background-color="#fff"
                dense
                hide-details="auto"
                class="q-autocomplete shadow-0"
            >
            </v-autocomplete>
            <!-- { name: 'Open Item', id: -1 }, -->
          </v-col>
          <v-col md="3" v-if="productCategoryId == -1">
            <label for="">Product name</label>
            <v-text-field
                v-model="selectedProduct.title"
                label=""
                required
                outlined
                background-color="#fff"
                dense
                hide-details="auto"
                class="q-text-field shadow-0 add-on"
            >
              <template v-slot:append>
                <v-menu
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="true"
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-flex align-center open-product">
                      {{ selectedProduct.rental == true ? "Base" : "Addon" }}
                      <v-icon small color="#111111">mdi-chevron-down</v-icon>
                    </div>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-radio-group v-model="selectedProduct.rental" column>
                        <v-radio
                            :disabled="repeatId > 0 ? true : false"
                            label="Base Product"
                            :value="true"
                        ></v-radio>
                        <v-radio label="Addon Product" :value="false"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="4" v-else>
            <label for="">Select Product</label>
            <v-autocomplete
                v-model="selectedProduct"
                label=""
                required
                return-object
                :items="getProducts()"
                item-value="id"
                item-text="name"
                outlined
                background-color="#fff"
                dense
                hide-details="auto"
                class="q-autocomplete shadow-0"
            ></v-autocomplete>
          </v-col>
          <v-col :md="productCategoryId == -1 ? 1 : 2">
            <label for="">Quantity</label>
            <v-text-field
                label=""
                outlined
                background-color="#fff"
                type="number"
                min="1"
                v-model="selectedProduct.quantity"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            ></v-text-field>
          </v-col>
          <v-col md="1" v-if="productCategoryId == -1">
            <label for="">Tax*</label>
            <v-select
                label=""
                v-model="selectedProduct.tax_type_id"
                item-value="value"
                item-text="text"
                hint="Required tax"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="taxTypes"
                outlined
                @change="taxChange"
                background-color="#fff"
                dense
                hide-details="auto"
                class="q-autocomplete shadow-0"
            ></v-select>
          </v-col>
          <v-col md="2" v-if="productCategoryId == -1">
            <label for="">Price (Pre Tax)*</label>
            <v-text-field
                label=""
                outlined
                rows="2"
                :prefix="currencyCode"
                background-color="#fff"
                required
                v-model="selectedProduct.pre_tax_price"
                @change="calculateTaxVariation($event, 'pre')"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            ></v-text-field>
          </v-col>
          <v-col md="2" v-if="productCategoryId == -1">
            <label for="">Price (Post Tax)*</label>
            <v-text-field
                label=""
                outlined
                rows="2"
                :prefix="currencyCode"
                background-color="#fff"
                required
                @change="calculateTaxVariation($event, 'post')"
                v-model="selectedProduct.total_price"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            ></v-text-field>
          </v-col>
          <v-col md="2" v-else>
            <label for="">Price</label>
            <v-text-field
                label=""
                :readonly="productCategoryId != -1"
                outlined
                background-color="#fff"
                v-model="selectedProduct.total_amount"
                :suffix="currencyCode"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            >
            </v-text-field>
          </v-col>
          <v-col md="1">
            <v-btn
                class=" white--text teal-color mt-5"
                height="41"
                block
                @click="addProduct"
            >Add</v-btn
            >
          </v-col>
        </div>
        <div class="ml-6 d-flex flex-column" v-if="products && products.length > 0">
          <span class="ml-2" style="color: #111111; font-size: 14px; font-weight: 600">Added Products</span>
          <div>
            <v-chip
                label
                color="#F5F8FA"
                text-color="gray"
                class="ml-2 mb-2 bordered"
                v-for="(product, index) in products"
                :key="index"
                :close="repeatId && product.rental ? false : true"
                @click:close="removeProduct(index)"
            >
              <v-avatar left>
                <view-image :image="product.image_path"></view-image>
              </v-avatar>
              {{ product.name }} x
              {{ product.quantity | numberFormatter(3) }}
              -
              {{ product.total_price | toCurrency }}
              <span
                  v-if="product.discount != null"
                  class="text-decoration-line-through pl-1"
              >
                {{ product.discount.actual_total | toCurrency }}</span
              >
            </v-chip>
          </div>
        </div>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    name: "ProductSelection",
    props: {
      venueServiceId: { type: Number, default: 0 },
      categories: { type: Array },
      categoriesList: { type: Array },
      promotions: { type: Array },
      bookingForm: { type: Object },
      disablePromotion: { type: Boolean, default: false },
      products: { type: Array },
      productCombinations: { type: Array },
      deletedProducts: { type: Array },
      currentOrderProducts: { type: Array },
      productCatId: { type: Number, default: null },
      repeatId: { type: Number, default: null },
      taxTypes: { type: Array },
    },
    data() {
      return {
        selectedProduct: { quantity: 1, price: 0 },
        customer: null,
        productCategoryId: null,
        price: 0,
        total_price: 0,
      };
    },
    mounted() {},
  
    methods: {
      getProducts() {
        let found = false;
        let membershipArr = [];
        let removeArr = [];
        let keepArr = [];
        let membership_ids = this.bookingForm.membership_id
          ? this.bookingForm.membership_id
          : null;
        if (membership_ids && membership_ids != null) {
          membershipArr = membership_ids.split(",");
        }
        if (this.productCategoryId != null) {
          let products = this.categories.find(
            (item) => item.id == this.productCategoryId
          ).products;
  
          membershipArr.forEach((ele) => {
            products.forEach((element) => {
              if (
                element.is_membership_only == 1 &&
                element.membership_id.split(",").includes(ele)
              ) {
                found = true;
              }
            });
          });
          if (!found) {
            products = products.filter((ele) => {
              return ele.is_membership_only == 0;
            });
          }
          return products;
        }
  
        if (this.productCategoryId == null) {
          let products = [];
          this.categories.forEach((category) => {
            category.products.forEach((product) => {
              product.category_id = category.id;
              products.push(product);
            });
          });
  
          membershipArr.forEach((ele) => {
            products.forEach((element) => {
              if (element.is_membership_only == 1) {
                if (element.membership_id.split(",").includes(ele)) {
                  found = true;
                  keepArr.push(element.id);
                } else {
                  removeArr.push(element.id);
                }
              }
            });
          });
          if (!found) {
            products = products.filter((ele) => {
              return ele.is_membership_only == 0;
            });
          }
  
          removeArr = removeArr.filter((el) => {
            return !keepArr.includes(el);
          });
  
          if (removeArr.length > 0) {
            return products.filter((ele) => {
              return !removeArr.includes(ele.id);
            });
          } else {
            return products;
          }
        }
        return [];
      },
      categoryChange(e) {
        if (e == -1) {
          this.selectedProduct.rental = false;
          this.selectedProduct.product_type_id = 6;
        }
      },
      addProduct() {
        // let quantity = this.quantity ? this.quantity : 1
        let quantity = this.selectedProduct.quantity && this.selectedProduct.quantity > 0? this.selectedProduct.quantity: 1;
        let sales = 0;
        if (this.selectedProduct.inventory_enable) {
          if (this.selectedProduct.inventory_period == "T") {
            sales = this.selectedProduct.time_sale? this.selectedProduct.time_sale: 0;
          } else {
            sales = this.selectedProduct.sales ? this.selectedProduct.sales : 0;
          }
          let totalQty = sales + parseFloat(quantity);
          if (parseFloat(this.selectedProduct.actual_qty) < totalQty) {
            if (this.selectedProduct.actual_qty - sales <= 0) {
              this.showError("Product not available!");
            } else {
              this.showError(`Only ${this.selectedProduct.actual_qty - sales} product available!`);
            }
            return;
          }
        }
        if (this.selectedProduct.id == null && this.selectedProduct.title == null) {
          this.showError("Please add product");
          return;
        }
  
        if (this.selectedProduct.inventory_enable) {
          this.selectedProduct.sales += parseFloat(quantity);
        }
        let price = parseFloat(this.selectedProduct.price) * parseFloat(quantity);
  
        if (this.selectedProduct.title != null) {
          if (this.selectedProduct.tax_type_id == null) {
            this.showError("Please select tax");
            return;
          }
          if (this.selectedProduct.price == null) {
            this.showError("Please add price");
            return;
          }
  
          this.selectedProduct.id = null;
          this.selectedProduct.name = this.selectedProduct.title;
  
          if (this.selectedProduct.tax_type_id == 1) {
            this.selectedProduct.tax_amount = this.selectedProduct.total_price - this.selectedProduct.price;
          }
        }
  
        let pIndex = this.products.findIndex((item) =>
          item.product_id ? item.product_id == this.selectedProduct.id : ""
        );
        console.log("selectedProduct");
        console.log(this.selectedProduct);
        if (pIndex == -1) {
          var obj = {
            product_id: this.selectedProduct.id ? this.selectedProduct.id : 0,
            price: price,
            product_price: this.selectedProduct.price,
            name: this.selectedProduct.name,
            tax: (this.selectedProduct.tax_amount? this.selectedProduct.tax_amount: 0) * quantity,
            tax_amount:this.selectedProduct.tax_amount? this.selectedProduct.tax_amount: 0,
            quantity: parseFloat(quantity),
            product_type_id: this.selectedProduct.product_type_id,
            inventory_enable: this.selectedProduct.inventory_enable? true: false,
            venue_service_id: this.venueServiceId,
            category_id: this.selectedProduct.category_id,
            participant_count: this.selectedProduct.participant_count,
            rental: this.selectedProduct.rental == true ? true : false,
            is_full_day: this.selectedProduct.is_full_day,
            total_price: price + parseFloat(this.selectedProduct.tax_amount? this.selectedProduct.tax_amount: 0) * quantity,
          };
          console.log("modify obj");
          console.log(obj);
          this.products.push(obj);
          if (this.repeatId) {
            this.currentOrderProducts.push(obj);
            this.$emit("setCurrentOrderProducts", this.currentOrderProducts);
          }
        } else {
         
          let newQuantity = parseFloat(this.products[pIndex].quantity) + parseFloat(quantity);
          let newTax = parseFloat((this.selectedProduct.tax_amount? this.selectedProduct.tax_amount: 0) * newQuantity);
        
          this.products[pIndex].quantity = newQuantity;
          this.products[pIndex].price = this.selectedProduct.price * newQuantity;
          this.products[pIndex].participant_count = this.selectedProduct.participant_count;
          // this.products[pIndex].total_price = this.products[pIndex].price + this.products[pIndex].tax * newQuantity;
          this.products[pIndex].total_price = this.products[pIndex].price + newTax;
          this.products[pIndex].tax = newTax;
  
          if (this.repeatId) {
            var findIndex = this.currentOrderProducts.findIndex((x) => x.product_id == this.products[pIndex].product_id);
            this.currentOrderProducts[findIndex] = this.products[pIndex];
            this.$emit("setCurrentOrderProducts", this.currentOrderProducts);
          }
  
          if (this.products[pIndex].inventory_enable) {
            if (this.products[pIndex].order_item_id) {
              this.deletedProducts.push(this.products[pIndex].order_item_id);
              this.$emit("deleteProduct", this.deletedProducts);
            }
          }
        }
  
        this.$emit("setCustomerProduct", this.products);
        this.selectedProduct = {};
        this.$forceUpdate();
      },
  
      taxChange() {
        if (this.selectedProduct.price) {
          this.calculateTaxVariation(this.selectedProduct.price, "pre");
        } else if (this.selectedProduct.total_price) {
          this.calculateTaxVariation(this.selectedProduct.total_price, "post");
        }
      },
      calculateTaxVariation(amount, type) {
        let taxTypeId = this.selectedProduct.tax_type_id;
        let taxPercentage = 0;
        if (taxTypeId) {
          taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
        }
        const priceData = this.getTaxVariation(type, taxPercentage, amount);
        this.selectedProduct.price = priceData.price;
        this.selectedProduct.pre_tax_price = priceData.price.toFixed(2);
        this.selectedProduct.total_price = priceData.total_price;
        this.$forceUpdate();
  
        // if (selectedProduct === null) {
        //   let taxTypeId = this.selectedProduct.tax_type_id;
        //   let taxPercentage = 0;
        //   if (taxTypeId) {
        //     taxPercentage = this.taxTypes.find(
        //       (item) => item.value == taxTypeId
        //     ).percentage;
        //   }
        //   const priceData = this.getTaxVariation(type, taxPercentage, amount);
        //   this.selectedProduct.price = priceData.price;
        //   this.selectedProduct.total_price = priceData.total_price;
        // } else {
        //   let taxTypeId = selectedProduct.tax_type_id;
        //   let taxPercentage = 0;
        //   if (taxTypeId) {
        //     taxPercentage = this.taxTypes.find(
        //       (item) => item.value == taxTypeId
        //     ).percentage;
        //   }
        //   const priceData = this.getTaxVariation(type, taxPercentage, amount);
        //   selectedProduct.price = priceData.price;
        //   selectedProduct.total_price = priceData.total_price;
        // }
        // this.$forceUpdate();
      },
      removeProduct(productIndex) {
        this.$emit("removeProduct", productIndex);
      },
      chooseRentalCombination() {
        this.$emit("chooseRentalCombination");
      },
    },
  };
  </script>
  <style>
  
  .add-on .v-input__append-inner {
    margin-top:6px !important;
  }
  
  .open-product {
    background-color: #F5F8FA;
    border: 1px solid black;
    cursor: pointer;
    height: 30px;
    width: 60px;
    color: #111111;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    transition: "border-radius 200ms ease-in-out";
    border-radius: 4px;
    text-align: center;
  }
  </style>
  