<template>
 <div class="absolute cart-holder">
   <div class="bg-white p-4  cart-preview shadow-2">
     <div class="cart">
       <div class="cart-header">
         Your Cart <span class="count">{{ cartCount }}</span>
         <span class="tex-right view-offer" @click="checkProductOffers">View Offers</span>
       </div>
       <div class="cart-items">
         <div v-for="(order,oi) in getCartItems" :key="`o_${oi}`">
           <div v-for="(product,pi) in order.products" :key="pi" class="my-7">
             <CartProductCard v-if="!product.isAddon" :item="order" :product="product"
                              @removeCartItem="removeCartItem(oi,pi)"/>
             <CartAddonCard
                 v-else
                 :item="order"
                 :product="product"
             />
           </div>
         </div>
       </div>
     </div>
     <v-btn class="text-capitalize blue-color text-white" style="width: inherit; max-width: 93%" absolute bottom elevation="0" height="48" @click="goToCart">
       <SvgIcon text="Proceed to Checkout" class="svg-fill-white">
         <template #icon>
           <CartIcon/>
         </template>
       </SvgIcon>
     </v-btn>
   </div>
   <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeOfferPopup" />
 </div>
</template>

<script>
import CartAddonCard from "@/components/Shop/Cart/CartAddonCard.vue";
import CartProductCard from "@/components/Shop/Cart/CartProductCard.vue";
import CartIcon from "@/assets/images/retail/cart-management.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";
export default {
  name: "CartPreview",
  components: {SvgIcon, CartIcon, CartProductCard, CartAddonCard,PackageCardPopup},
  computed: {
    cartCount() {
      return this.$store.getters.getCartCount;
    },
    getCartItems() {
      return this.$store.getters.getCartItems;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        // window.addEventListener('scroll', this.handleScroll); // Add scroll event listener
        // console.log("working");
        // // After content is rendered, adjust height based on scrollbar
        // this.adjustHeightBasedOnScrollbar(); // Adjust on mount
        // window.addEventListener('resize', this.adjustHeightBasedOnScrollbar); // Adjust on window resize
      });
    }, 1000); // Simulate a delay
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll);
    // window.removeEventListener('resize', this.adjustHeightBasedOnScrollbar);
  },
  data() {
    return {
      offers: [],
      showOfferPopup:false,
      divHeight: '90vh',
    };
  },
  methods: {
    handleScroll() {
      // Get the total scrollable height of the page
      const scrollableHeight = document.documentElement.scrollHeight;
      // Get the current scroll position (from the top of the page)
      const scrolledFromTop = window.scrollY;
      // Get the height of the visible part of the page (the viewport)
      const viewportHeight = window.innerHeight;

      // Check if the user has scrolled to the bottom of the page
      if (scrolledFromTop + viewportHeight >= scrollableHeight) {
        this.divHeight = '90vh'; // Increase height when at bottom
      }else{
        this.divHeight = '93vh';
      }
    },
    adjustHeightBasedOnScrollbar() {
      // Check if scrollbar is visible
      const hasScrollbar = document.documentElement.scrollHeight > window.innerHeight;

      // Adjust height based on scrollbar visibility
      if (hasScrollbar) {
        this.divHeight = '93vh'; // Increase height when scrollbar is visible
      } else {
        this.divHeight = '90vh'; // Default height when no scrollbar
      }
    },
    removeCartItem(oIndex, pIndex) {
      this.$store.dispatch("removeCartItem", {oi: oIndex, pi: pIndex});
    },
    goToCart() {
      this.$router.push('/shop/cart');
    },
    checkProductOffers(){
      this.offers = [];
      this.showLoader("loading");
      this.$store.dispatch('searchOffer',false).then( (response) => {
        if (response && response.status === 200 && response.data.status === true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.showError("Offer not available")
            this.offers = [];
          }
          this.hideLoader();
        }else{
          this.hideLoader();
        }
      }).catch((error) => {
        console.log(error);
        this.showError("Offer not available");
        this.hideLoader(); // Ensure the loader is hidden in case of error
      });
    },
    closeOfferPopup(){
      this.showOfferPopup = false;
      this.offers = [];
    },
  }
}
</script>