<template>
  <div>
    <div class="d-flex justify-start align-center mb-6">
      <div class="nv_item d-flex pointer">
        <div class="nv_item qp-tab-nav" v-if="venueList">
          <v-select
              v-model="selectedVenueId"
              :height="46"
              :items="venueList"
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="rgba(79, 174, 175, 0.1)"
              class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete venue-select"
              color="#4FAEAF"
              dense
              hide-details
              item-color="#4FAEAF"
              item-text="name"
              item-value="id"
              outlined
              placeholder="Venue Name"
              style="width: 140px;"
              @change="changeVenue"
          ></v-select>
        </div>
      </div>
      <div class="d-flex p-2  bg-white bordered w-fit tabs" v-if="$store.getters.checkWriteShopPermission('spd')">
        <template v-for="(tab) in tabs">
          <div v-if="$store.getters.checkWriteShopPermission(tab.permission)" v-bind:key="tab.name" class="nv_item d-flex pointer tab" @click="gotoPage(tab.path[0])">
            <SvgIcon 
            :class="{
                'qp-tab-nav-is-active': tab.path && tab.path.includes($route.path),
                'qp-tab-nav': tab.path && !tab.path.includes($route.path)
              }"
              :text="tab.name"
              class="text-thin-gray"
            >
              <template v-slot:icon>
                <component :is="tab.icon" :component="tab.icon" />
              </template>
            </SvgIcon>
          </div>
        </template>
      </div>
    </div>
    <CartPreview />
  </div>
</template>

<script>
import { EventBus } from '@/main.js';
import SvgIcon from "@/components/Image/SvgIcon.vue";
import EventsIcon from "@/assets/images/events/event.svg";
import FacilityIcon from "@/assets/images/facilities/facility.svg";
import TrainerIcon from "@/assets/images/trainers/trainers_icon.svg";
import AcademyIcon from "@/assets/images/workshops/workshops.svg";
import MembershipIcon from "@/assets/images/memberships/memberships.svg"
import RetailIcon from "@/assets/images/retail/retail.svg"
import CartIcon from "@/assets/images/retail/cart-management.svg"
import CartPreview from "@/components/Shop/Cart/CartPreview.vue";
import OffersIcon from "@/assets/images/misc/offers.svg";
export default {
  components: {
    CartPreview,
    AcademyIcon,
    TrainerIcon,
    FacilityIcon,
    EventsIcon,
    SvgIcon,
    MembershipIcon,
    RetailIcon,
    CartIcon,
    OffersIcon,
  },
  mounted(){
    this.selectedVenueId =  this.$store.getters.svid?this.$store.getters.svid:this.$store.getters.userInfo.venue_id;
    if(this.venueList && this.venueList.length  === 0){
      if (this.selectedVenueId) {
        this.$store.dispatch("shopSwitchVenue",this.selectedVenueId).then( (res) => {
          const venues = res.data.data.venues;
          const v = venues.find(v => v.id === this.selectedVenueId);
          if (v) {
            this.selectedVenueId = v.id
          }
        });
      }
    }
    this.getQueryParams();
  },
  computed: {

    cartCount() {
      return this.$store.getters.getCartCount;
    },
    venueList(){
      return this.$store.getters.getShopAvailableVenues;
    }
  },
  methods: {
    gotoPage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    changeVenue(){
      this.removeQueryParameters();
      this.$store.dispatch('addReloadShopModule','');
      // need to get the permissions of modules
      this.showLoader("Switching..");
      this.$store.dispatch("switchSVID",this.selectedVenueId).then( () => {
        this.$store.dispatch("shopSwitchVenue",this.selectedVenueId).then( () => {
          this.hideLoader();
          const data = this.$store.getters.getShopPermissions.data;
          const currentTab = this.tabs.find(tab => tab.path.includes(this.$route.path))
          const isCurrentTabActive = data[currentTab.permission] === 1;
          if(!isCurrentTabActive){
            for (let index in this.tabs) {
              const tabData = this.tabs[index];
              if(data[tabData.permission] === 1){
                this.gotoPage(tabData.path[0]);
                break;
              }
            }
            return;
          }
          if(this.$route.path === '/shop/facility'){
            this.$store.dispatch('addReloadShopModule','facility');
          }else if(this.$route.path === '/shop/events'){
            this.$store.dispatch('addReloadShopModule','event')
          }else if(this.$route.path === '/shop/memberships'){
            this.$store.dispatch('addReloadShopModule','membership')
          }else if(this.$route.path === '/shop/trainers'){
            this.$store.dispatch('addReloadShopModule','trainer')
          }else if(this.$route.path === '/shop/academy'){
            this.$store.dispatch('addReloadShopModule','academy')
          }else if(this.$route.path === '/shop/offers'){
           this.$store.dispatch('addReloadShopModule','offer')
          }else if(this.$route.path === '/shop/retail'){
           this.$store.dispatch('addReloadShopModule','retail')
          }else if(this.$route.path === '/shop/offers'){
            this.$store.dispatch('addReloadShopModule','offers')
          }
        })
      });
    },
    getQueryParams() {

      const query = this.$route.query;
      // Validate the 'vid' (Venue ID) parameter
      if (query.vid && !isNaN(query.vid) && query.vsid && !isNaN(query.vsid) && query.fid && !isNaN(query.fid)) {
        this.selectedVenueId = parseInt(query.vid);
        if(this.selectedVenueId){
          this.$store.dispatch('addReloadShopModule','');
          // need to get the permissions of modules
          this.showLoader("Switching..");
          this.$store.dispatch("switchSVID",this.selectedVenueId).then( () => {
            this.$store.dispatch("shopSwitchVenue",this.selectedVenueId).then( () => {
              this.hideLoader();
              const data = this.$store.getters.getShopPermissions.data;
              const currentTab = this.tabs.find(tab => tab.path.includes(this.$route.path))
              const isCurrentTabActive = data[currentTab.permission] === 1;
              if (!isCurrentTabActive) {
                for (let index in this.tabs) {
                  const tabData = this.tabs[index];
                  if (data[tabData.permission] === 1) {
                    this.gotoPage(tabData.path[0]);
                    break;
                  }
                }
                return;
              }
              EventBus.$emit('facility-query-event', { message: 'fqe-event' });
            });
          });
        }
      }
    },
    removeQueryParameters() {
      if (window.location.search) {
        // Get the base URL without query parameters
        const baseUrl = window.location.origin + window.location.pathname;

        // Use the History API to update the URL without reloading the page
        window.history.pushState({}, '', baseUrl);
      }
    }
  },
  data() {
    return {
      venue: null,
      selectedVenueId: null,
      tabs: [
        {
          name: "Facility",
          icon: FacilityIcon,
          path: ["/shop/facility"],
          permission: this.$modules.facility.schedule.slug
        },
        {
          name: "Events",
          icon: EventsIcon,
          path: ["/shop/events"],
          permission: this.$modules.events.schedule.slug
        },
        {
          name: "Memberships",
          icon: MembershipIcon,
          path: ["/shop/memberships"],
          permission: this.$modules.memberships.dashboard.slug
        },
        {
          name: "Trainers",
          icon: TrainerIcon,
          path: ["/shop/trainers"],
          permission: this.$modules.trainers.dashboard.slug
        },
        {
          name: "Academy",
          icon: AcademyIcon,
          path: ["/shop/academy"],
          permission: this.$modules.workshops.schedule.slug
        },
        {
          name: "Retail",
          icon: RetailIcon,
          path: ["/shop/retail"],
          permission: this.$modules.pos.dashboard.slug
        },
        {
          name: "Offers",
          icon: OffersIcon,
          path: ["/shop/offers"],
          permission: this.$modules.workshops.schedule.slug
        },
      ],
      permissions:null,
    }
  },
}
</script>
<style  scoped lang="scss">
  ::v-deep {
    .venue-select{
      width: 140px;
      .v-input__control {
        height: 42px !important;
      }
      .v-input__control .v-input__slot {
        height: 42px !important;
        min-height: 42px !important;
      }
      .v-input__icon {
        height: 28px;
      }
      .v-select__selection {
          color: #4FAEAF;
      }
    } 
  }
</style>


